//
//
//
//

export default {
  name: 'IndexPage',

  created() {
    this.$router.push({
      path: 'authorization/sign-in',
    });
  },

}
